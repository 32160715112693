import {
  Description,
  FolderZip,
  Image as ImageIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { proFreelancerService } from "../../../../api/services/pro-freelancer";
import {
  IProFreelancerRequestDetail,
  PRO_FREELANCER_REQUEST_STATUS,
} from "../../../../api/types/pro-freelancer";
import ReceiptCard from "../../../../components/Kit/ReceiptCard";
import AdminActions from "./AdminActions";
import AdminActionsHistory from "./AdminActionsHistory";
import { getFiles, getImages, getPersonalData } from "./helper";
import Separator from "./Separator";

const mockAdminData = [
  {
    adminEmail: "admin1@example.com",
    rejectionReason: "Incomplete profile information",
    approvalDateTime: "2024-12-20 10:15",
    rejectionDateTime: undefined,
    totalApplications: 150,
    totalRejections: 50,
  },
  {
    adminEmail: "admin2@example.com",
    rejectionReason:
      "Invalid documents submitted Invalid documents submittedInvalid documents submittedInvalid documents submitted Invalid documents submitted",
    approvalDateTime: undefined,
    rejectionDateTime: "2024-12-19 14:30",
    totalApplications: 200,
    totalRejections: 80,
  },
  {
    adminEmail: "admin3@example.com",
    rejectionReason: undefined,
    approvalDateTime: undefined,
    rejectionDateTime: undefined,
    totalApplications: 300,
    totalRejections: 120,
  },
];

const ProFreelancerDetails = () => {
  const getFileIcon = (type: string) => {
    switch (type) {
      case "pdf":
        return <Description color="error" />;
      case "image":
        return <ImageIcon color="primary" />;
      case "zip":
        return <FolderZip color="secondary" />;
      default:
        return <Description />;
    }
  };
  const routeParams = useParams<{ id: string }>();
  const [contractDetails, setContractDetails] =
    useState<IProFreelancerRequestDetail>();
  console.log("contractDetails", contractDetails);
  const fetchContractDetails = useCallback(async () => {
    if (routeParams.id) {
      try {
        Promise.all([
          proFreelancerService.getDetails(routeParams.id),
          // contractService.getContractAvailableActions(+routeParams.id),
        ])
          .then((responses) => {
            setContractDetails(responses[0].data.payload);
            // updateAvailableStatusOptions(responses[1].data.payload.actions);
          })
          .catch((error) => {
            throw new Error(error);
          });
        // setLoading(true);
      } catch (error) {
        console.error(error);
      } finally {
        // setLoading(false);
      }
    }
  }, [routeParams.id]);

  useEffect(() => {
    fetchContractDetails();
  }, [fetchContractDetails]);

  const canDoActions = () => {
    if (
      contractDetails?.proFreelancer.status ===
      PRO_FREELANCER_REQUEST_STATUS.PENDING
    )
      return true;
    return false;
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h3" textAlign="center" gutterBottom>
        Pro Freelancer Request
      </Typography>
      <Divider sx={{ mb: 4 }} />

      <Grid container spacing={4} sx={{ mb: 4 }}>
        {/* Personal Information Section */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Personal Information
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {contractDetails &&
                getPersonalData(contractDetails).map((item, key) => (
                  <Box key={key} sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {item.label}:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.value}
                    </Typography>
                  </Box>
                ))}
            </CardContent>
          </Card>
        </Grid>

        {/* Application Files Section */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Application Files
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                {contractDetails &&
                  getFiles(contractDetails).map((file, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Card
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          boxShadow: 2,
                          p: 1,
                          transition: "transform 0.2s, box-shadow 0.2s",
                          "&:hover": {
                            transform: "scale(1.05)",
                            boxShadow: 4,
                            backgroundColor: "#f5f5f5",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => window.open(file.url, "_blank")}>
                        <Avatar sx={{ mr: 2 }}>{getFileIcon(file.type)}</Avatar>
                        <Box>
                          <Typography variant="body2">{file.name}</Typography>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box display="flex" gap={3}>
        {contractDetails &&
          getImages(contractDetails).map((imgItem, index) => {
            return (
              <ReceiptCard
                key={index}
                title={imgItem.name}
                imageUrl={imgItem.url}
              />
            );
          })}
      </Box>
      {/* Action Buttons */}
      <Box display="flex" justifyContent="center" gap={3} mt={4}>
        {routeParams.id && canDoActions() ? (
          <AdminActions requestId={routeParams.id} />
        ) : null}
      </Box>

      {contractDetails?.flows?.length === 1 ? null : (
        <>
          <Separator />
          <Card
            elevation={4}
            sx={{
              maxWidth: 500,
              margin: 2,
              padding: 2,
              borderRadius: 2,
              backgroundColor: "#fdfdfd",
            }}>
            {contractDetails?.flows
              .filter((_, index) => index !== 0)
              .map((data, index) => (
                <AdminActionsHistory
                  key={index}
                  {...data}
                  isLastItem={index === 0}
                />
              ))}
          </Card>
        </>
      )}
    </Box>
  );
};

export default ProFreelancerDetails;
