import { CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import dayjs from "dayjs";

export interface AdminActionHistory {
  adminEmail: string;
  rejectionReason?: string;
  approvalDateAndTime?: string;
  rejectDateAndTime?: string;
  applyCount: number;
  rejectCount: number;
}

type Props = AdminActionHistory & { isLastItem: boolean };

const AdminActionsHistory: React.FC<Props> = ({
  adminEmail,
  rejectionReason,
  approvalDateAndTime,
  rejectDateAndTime,
  applyCount,
  rejectCount,
  isLastItem,
}) => {
  const status = approvalDateAndTime
    ? "Approved"
    : rejectDateAndTime
    ? "Rejected"
    : "Pending";

  // if (status === "Pending") {
  //   return null;
  // }

  return (
    <CardContent>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
          {status === "Approved"
            ? "✅ Approved"
            : status === "Rejected"
            ? "❌ Rejected"
            : "⏳ Pending"}
        </Typography>
      </Stack>
      <Divider sx={{ marginBottom: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ fontWeight: 500, color: "#666" }}>
            Admin Email:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: "#333" }}>
            {adminEmail || "N/A"}
          </Typography>
        </Grid>
        {rejectionReason && status === "Rejected" && (
          <>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#666" }}>
                Rejection Reason:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "#333" }}>
                {rejectionReason}
              </Typography>
            </Grid>
          </>
        )}
        {approvalDateAndTime ? (
          <>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#666" }}>
                Approval Date & Time:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "#333" }}>
                {dayjs(approvalDateAndTime).format("YYYY-MM-DD HH:mm")}
              </Typography>
            </Grid>
          </>
        ) : null}
        {rejectDateAndTime ? (
          <>
            {" "}
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#666" }}>
                Rejection Date & Time:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "#333" }}>
                {rejectDateAndTime || "N/A"}
              </Typography>
            </Grid>
          </>
        ) : null}

        {isLastItem ? (
          <>
            {" "}
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#666" }}>
                Total Applications:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "#333" }}>
                {applyCount}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#666" }}>
                Total Rejections:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ color: "#333" }}>
                {rejectCount}
              </Typography>
            </Grid>
          </>
        ) : null}
      </Grid>
    </CardContent>
  );
};

export default AdminActionsHistory;
